#table-sparkline{
    width: 100%;
}

#container {
    display: flex;
}

#container > div {
    flex: 1 1 20%;
    box-sizing: border-box; /* Para incluir padding y border en el ancho total */
}

#containerImg {
    display: flex;
}

#containerImg > div {
    flex: 1 1 25%;
    box-sizing: border-box; /* Para incluir padding y border en el ancho total */
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 660px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

.pd_card{
    padding: 0 40px;
  }
  
  .custom-file-upload {
      display: inline-block;
      padding: 10px 20px;
      background-color: #007BFF;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
  
  .toast-container {
      position: absolute;
      z-index: 1;
      right: 0;
  }
  
  .button-container {
      position: relative;
  }
    
  
  /* DatePicker */
  .custom-datepicker {
    font-size: 1.4em;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    width: 100px;
  }
  
  .custom-datepicker:hover {
    border-color: #888;
  }
  
  .custom-datepicker .react-datepicker__year-text {
    color: #444;
  }
  
  .custom-datepicker .react-datepicker__year-text.react-datepicker__year-text--selected,
  .custom-datepicker .react-datepicker__year-text.react-datepicker__year-text--today {
    color: #fff;
    background-color: #007bff;
  }
  
  .react-datepicker__year-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .react-datepicker__year-text:hover {
    background-color: #82868a;
  }
  
  .react-datepicker__year-text.react-datepicker__year-text--today {
    color: #fff;
    background-color: #c7c1f7;
  }
  .react-datepicker__year-text.react-datepicker__year-text--selected{
    color: #fff;
    background-color: #0d6efd;
  }